import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroBanner = () => {
  const {
    sanityOurTeamPage: { heroBannerImage: data },
  } = useStaticQuery(graphql`
    query ourTeamHeroQuery {
      sanityOurTeamPage {
        heroBannerImage {
          asset {
            url
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `)

  return <GatsbyImage image={data.asset.gatsbyImageData} alt="our team hero" />
}

export default HeroBanner
