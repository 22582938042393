import React from "react"
import SanityMuxPlayer from "sanity-mux-player"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./video.module.scss"
import Video from "../shared/video"

const TeamVideo = () => {
  const { sanityOurTeamPage } = useStaticQuery(graphql`
    query ourTeamVideoQuery {
      sanityOurTeamPage {
        ourTeamPageHeroVideo {
          asset {
            assetId
            filename
            playbackId
            status
            thumbTime
          }
        }
        video {
          url
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      <div className={styles.wrapper}>
        <Video
          url={sanityOurTeamPage.video.url}
          thumbnail={sanityOurTeamPage.video.image}
          width="100%"
          height="100%"
          imageWidth="100%"
        />
      </div>
    </div>
  )
}

export default TeamVideo
