// extracted by mini-css-extract-plugin
export var colorLine = "members-module--colorLine--ocjy6";
export var container = "members-module--container--491FN";
export var content = "members-module--content--+A6pK";
export var description = "members-module--description--mDEcD";
export var fullName = "members-module--fullName--bfa7t";
export var imagePlaceHolder = "members-module--imagePlaceHolder--fUKxx";
export var jobTitle = "members-module--jobTitle--vB6+y";
export var profileImage = "members-module--profileImage--GXVQi";
export var section = "members-module--section--zDmwi";
export var teamMember = "members-module--teamMember--dA3RL";
export var teamMembers = "members-module--teamMembers--kxqrd";