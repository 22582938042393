import React from "react"
import Layout from "../components/layout"
import SEO from "../components/ourTeam/seo"
import HeroBanner from "../components/ourTeam/heroBanner"
import Intro from "../components/ourTeam/intro/"
import TeamVideo from "../components/ourTeam/video"
import Members from "../components/ourTeam/members"

const OurTeamPage = () => {
  return (
    <Layout>
      <SEO />
      <HeroBanner />
      <Intro />
      <TeamVideo />
      <Members />
    </Layout>
  )
}

export default OurTeamPage
