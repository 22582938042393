import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { v4 as uuid } from "uuid"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./members.module.scss"

const Members = () => {
  const {
    sanityOurTeamPage: { teamMembers },
  } = useStaticQuery(graphql`
    query ourTeamMembersQuery {
      sanityOurTeamPage {
        teamMembers {
          profileImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          fullName
          jobTitle
          description
          colorlist
        }
        otherMembers {
          profileImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          fullName
          jobTitle
          description
          colorlist
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <h3>Leadership Team</h3>
        <div className={styles.teamMembers}>
          {teamMembers.map(member => (
            <div key={uuid()} className={styles.teamMember}>
              {member.profileImage ? (
                <GatsbyImage
                  image={member.profileImage.asset.gatsbyImageData}
                  alt={member.profileImage.asset.originalFilename}
                  className={styles.profileImage}
                />
              ) : (
                <div className={styles.imagePlaceHolder}></div>
              )}
              <div className={styles.content}>
                <h4 className={styles.fullName}>{member.fullName || ""}</h4>
                <h3 className={styles.jobTitle}>{member.jobTitle || ""}</h3>
                <p className={styles.description}>{member.description || ""}</p>
              </div>
              <span
                className={styles.colorLine}
                style={{ backgroundColor: member.colorlist || "#eeeeee" }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Members
