import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ReactComponent as WaveTop } from "./wave-top.svg"
import { ReactComponent as WaveBottom } from "./wave-bottom.svg"
import BlocksContent from "../../blocksContent"
import * as styles from "./style.module.scss"

const Intro = () => {
  const {
    sanityOurTeamPage: { promoText: data },
  } = useStaticQuery(graphql`
    query ourTeamIntroQuery {
      sanityOurTeamPage {
        promoText {
          title
          subTitle
          ctaText
          citaTextRich {
            _rawColumnContent
          }
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      <WaveTop />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className={styles.title}>{data.title}</h1>
          <h2 className={styles.subTitle}>{data.subTitle}</h2>
          {/* <p className={styles.ctaText}>{data.ctaText}</p> */}
          <BlocksContent blocks={data.citaTextRich._rawColumnContent} />
        </div>
      </div>
      <WaveBottom />
    </div>
  )
}

export default Intro
