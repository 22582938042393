// extracted by mini-css-extract-plugin
export var content = "style-module--content--7a08u";
export var ctaText = "style-module--ctaText--vmmC1";
export var isStyleH1 = "style-module--is-style-h1--q1YqI";
export var isStyleH2 = "style-module--is-style-h2--Q586a";
export var isStyleH3 = "style-module--is-style-h3--2vkxr";
export var isStyleH4 = "style-module--is-style-h4--m2mgH";
export var isStyleH5 = "style-module--is-style-h5--2Tt3I";
export var isStyleH6 = "style-module--is-style-h6--yttLp";
export var section = "style-module--section--omD4B";
export var subTitle = "style-module--subTitle--igs3B";
export var title = "style-module--title--yxljr";
export var wrapper = "style-module--wrapper--h9CdA";